import { createTheme, ThemeProvider } from '@mui/material/styles';
import React from 'react';
import './theme.scss';

const theme = createTheme({
  palette: {
    primary: {
      main: '#e64c3b',
    },
    secondary: {
      main: '#fdad78',
    },
    text: {
        primary: '#000',
        secondary: '#292929',
    },
    background: {
      default: '#efefef', 
    },
    paper:
    {
      main: '#F0EBE8',
    },
    footer:
    {
      main: '#cfcfcf',
    },
    hr:
    {
      main: '#b8b8b8',
    },
  },
});

function ThemeWrapper({ children }) {
  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  );
}

export default ThemeWrapper;
