import axios from "axios";
import { getBasketIds } from "../storage/LocalStorage";

export async function getProducts() {
  try {
    const response = await axios.get(
      "https://api.sushi-dobriy.ru/en/api/products/",
      { withCredentials: true },
    );
    const { productsByCategory } = groupByCategory(response.data);
    return { productsByCategory };
  } catch (error) {
    console.log("Error getting product: ", error);
    return { productsByCategory: {} };
  }
}

function formatProductData(product, selectedItem) {
  const formattedPrice = parseFloat(product.price);
  let formattedWeight = parseFloat(product.weight);

  let weightSuffix = "г";
  if (selectedItem === "пицца") {
    weightSuffix = "см";
  } else if (selectedItem === "напитки") {
    weightSuffix = "л";
  }

  formattedWeight = `${Number(formattedWeight)}${weightSuffix}`;

  return {
    id: product.id,
    name: product.name,
    price: Number(formattedPrice),
    weight: formattedWeight,
    description: product.description,
    img: product.img,
  };
}

function groupByCategory(data) {
  if (!data || !data.results) {
    console.error("Нет данных для обработки");
    return { productsByCategory: {} };
  }

  const groupedProducts = {};

  data.results.forEach((product) => {
    const categoryName = product.category.name;

    if (!groupedProducts[categoryName]) {
      groupedProducts[categoryName] = [];
    }
    groupedProducts[categoryName].push(
      formatProductData(product, categoryName.toLowerCase()),
    );
  });

  return { productsByCategory: groupedProducts };
}

export async function getBasketProducts() {
    const basketIds = getBasketIds();
    const ids = Object.keys(basketIds);

    if (ids.length === 0) {
        return [];
    }

    try {
        const params = ids.map(id => `ids=${id}`).join('&');
        
        const response = await axios.get(
            `https://api.sushi-dobriy.ru/en/api/products/by_ids/?${params}`,
        );
        
        console.log(response.data.map(product => formatProductData(product, product.category.name.toLowerCase())))
        return response.data.map(product => formatProductData(product, product.category.name.toLowerCase()));
    } catch (error) {
        console.error('Ошибка при запросе продуктов по ID:', error);
        return [];
    }
}
