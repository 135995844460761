import React, { useEffect, useState, useCallback } from 'react';
import './styles.scss';
import BasketProductsContainer from '../../components/BasketProducts/BasketProductsContainer/BasketProductsContainer';
import BasketProduct from '../../components/BasketProducts/BasketProduct/BasketProduct';
import { getBasketProducts } from '../../services/producs';
import { Button } from '@mui/material';
import OrderDataInput from '../../components/OrderDataInput/OrderDataInput';
import { makeOrder } from '../../services/order';
import { getProductCount } from '../../storage/LocalStorage';

const Basket = () => {
    const [products, setProducts] = useState([]);
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [name, setName] = useState('');
    const [orderStatus, setOrderStatus] = useState('');
    const [validation, setValidation] = useState(false);
    const [totalAmount, setTotalAmount] = useState(0);

    const updateProductsData = useCallback(async () => {
        const data = await getBasketProducts();

        const productsWithCount = data.map(product => ({
            ...product,
            count: getProductCount(product.id)
        }));

        setProducts(productsWithCount);
    }, []);

    useEffect(() => {
        updateProductsData();
    }, [orderStatus, updateProductsData]);


    useEffect(() => {
        const total = products.reduce((sum, product) => {
            const price = parseFloat(product.price) || 0;
            const count = parseInt(product.count, 10) || 0;
            return sum + price * count;
        }, 0);

        setTotalAmount(total);
    }, [products]);


    const handleProductUpdate = () => {

        setProducts(prevProducts =>
            prevProducts
                .map(product => ({
                    ...product,
                    count: getProductCount(product.id)
                }))
                .filter(product => product.count > 0)
        );
    };

    useEffect(() => {
        const handleChangeBasketCount = () => {
            updateProductsData();
        };

        window.addEventListener('basketCountUpdated', handleChangeBasketCount);

        return () => {
            window.removeEventListener('basketCountUpdated', handleChangeBasketCount);
        };
    }, [updateProductsData]);

    const handleOrderClick = async (isPickup) => {
        let orderAddress = address;
        if (isPickup) {
            orderAddress = 'Самовывоз';
        }

        if (await makeOrder(name, orderAddress, phone, isPickup)) {
            setOrderStatus('Заказ успешно создан. Скоро вам позвонит оператор для подтверждения заказа.');
        } else {
            setOrderStatus('Произошла ошибка на нашей стороне, попробуйте еще раз.');
        }

        const targetElement = document.getElementById('order_status-basket');
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handlePickupClick = () => {
        handleOrderClick(true);
    };

    const handleDeliveryClick = () => {
        handleOrderClick(false);
    };

    return (
        <div className='Basket pageMarginContainer tinyContainer'>
            {orderStatus && <h3 id='order_status-basket'>{orderStatus}</h3>}
            {products.length === 0 ? (
                <div className="empty-basket">
                    <h2>Корзина пуста</h2>
                    <Button href='/menu' variant='contained'>Меню</Button>
                </div>
            ) : (
                <>
                    <BasketProductsContainer>
                        {products.map((product) => (
                            <BasketProduct
                                key={product.id}
                                id={product.id}
                                title={product.name}
                                price={product.price}
                                weight={product.weight}
                                img={product.img}
                                description={product.description}
                                prodCount={product.count}
                                onUpdate={handleProductUpdate}
                            />
                        ))}
                    </BasketProductsContainer>
                    <hr />

                    <h3 className='order-total'>Сумма заказа: {isNaN(totalAmount) ? 0 : totalAmount} руб.</h3>

                    <OrderDataInput
                        setPhoneCB={setPhone}
                        setAddressCB={setAddress}
                        setNameCB={setName}
                        setValidationCB={setValidation}
                    />
                    <div className="order-buttons">
                        <Button
                            disabled={!validation || address.trim() === ''}
                            className='order-basket'
                            variant='contained'
                            onClick={handleDeliveryClick}
                        >
                            Доставка
                        </Button>
                        <Button
                            disabled={!validation || address.trim() !== ''}
                            className='order-basket'
                            variant='contained'
                            onClick={handlePickupClick}
                        >
                            Самовывоз
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
};

export default Basket;
