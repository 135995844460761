import React from 'react';
import './styles.scss'
import { List, ListItem, ListItemButton } from '@mui/material';

const SideMenu = ({orientation})=>
{
    return(
        <div className={`SideMenu ${orientation?'horizontal-side_menu':''}`} >
            <List>
                <ListItem>
                    <ListItemButton href='/'>
                        Главная
                    </ListItemButton>
                </ListItem>
                <ListItem>
                    <ListItemButton href='/menu'>
                        Меню
                    </ListItemButton>
                </ListItem>
                <ListItem>
                    <ListItemButton href='/delivery'>
                        Доставка
                    </ListItemButton>
                </ListItem>
                <ListItem>
                    <ListItemButton href='about'>
                        О нас
                    </ListItemButton>
                </ListItem>
                <ListItem>
                    <ListItemButton href='contacts'>
                        Контакты
                    </ListItemButton>
                </ListItem>
            </List>
        </div>
    );
};

export default SideMenu;