import React, { useEffect, useState } from 'react';
import './styles.scss';
import { TextField } from '@mui/material';

const OrderDataInput = ({ setPhoneCB, setAddressCB, setNameCB, setValidationCB }) => {
    const [phone, setPhone] = useState("+7");
    const [error, setError] = useState(false);
    const [address, setAddress] = useState("");
    const [name, setName] = useState("");

    const handleInputChange = (e) => {
        let value = e.target.value.replace(/[^\d]/g, '');

        if (!value.startsWith('7')) {
            value = '7' + value;
        }

        if (value.length > 11) {
            value = value.slice(0, 11);
        }

        setPhone(`+${value}`);

        if (value.length === 11) {
            setError(false);
        } else {
            setError(true);
        }
    };

    useEffect(() => {
        setPhoneCB(phone);
        setAddressCB(address);
        setNameCB(name);

        const isValid = name.trim() !== '' && phone.length === 12 && !error;
        setValidationCB(isValid);
    }, [phone, name, address, error, setPhoneCB, setAddressCB, setNameCB, setValidationCB]);

    return (
        <div className='OrderDataInput'>
            <TextField
                label="Имя"
                variant="outlined"
                type='text'
                color='primary'
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
            />
            <TextField
                id="inputPhone"
                value={phone}
                onChange={handleInputChange}
                variant="outlined"
                error={error}
                label='Номер телефона'
                required
            />
            <TextField
                id="inputAddress"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                variant="outlined"
                label='Адрес'
            />
        </div>
    );
};

export default OrderDataInput;
