import React from 'react';
import './styles.scss'

const BasketProductsContainer = ({children})=>
{
    return(
        <div className='BasketProductsContainer' >
            {children}
        </div>
    );
};

export default BasketProductsContainer;