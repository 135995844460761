import React, { useState } from 'react';
import './styles.scss';
import { Backdrop, Button, Modal } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import { addToBasket, delFromBasket } from '../../../storage/LocalStorage';
import ModalProduct from '../../Products/ModalProduct/ModalProduct';

const BasketProduct = ({ id, title, price, weight, img, description, prodCount, onUpdate}) => {
    const [count, setCount] = useState(prodCount);
    const [openModal, setOpenModal] = useState(false);
    const [isRemoving, setIsRemoving] = useState(false);

    const handleAddToBasketOnClick = () => {
        setCount(count + 1);
        addToBasket(id);
        onUpdate();
    };

    const handleDeleteFromBasketOnClick = () => {
        if (count > 1) {
            setCount(count - 1);
            delFromBasket(id);
        } else if (count === 1) {
            setIsRemoving(true);
            setTimeout(() => {
                setCount(0);
                delFromBasket(id);
            }, 300); 
        }
        onUpdate();
        
    };

    return (
        count > 0 && (
            <div className={`BasketProduct basketProd${id} ${isRemoving ? 'removing' : ''}`}>
                <button className='open_modal-basket_product' onClick={() => setOpenModal(true)}>
                    <img src={img} alt={title} />
                </button>
                <div className="content-basket_product">
                    <button className='open_modal-basket_product' onClick={() => setOpenModal(true)}>
                        <div className="title_desc-basket_product">
                            <h3>{title}</h3>
                            <p>{description}</p>
                        </div>
                    </button>
                    <div className="price_weight_button-product">
                        <div className="price_weight-product">
                            <span>{price} руб.</span>
                            <span>{weight}</span>
                        </div>
                        <div className="count_button-product">
                            <Button className='plus_minus-product' onClick={handleDeleteFromBasketOnClick}><Remove /></Button>
                            <span>{count}</span>
                            <Button onClick={handleAddToBasketOnClick} className='plus_minus-product'><Add /></Button>
                        </div>
                    </div>
                </div>

                <Modal
                    open={openModal}
                    onClose={() => setOpenModal(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                        backdrop: {
                            timeout: 1000,
                        },
                    }}
                >
                    <ModalProduct
                        id={id}
                        title={title}
                        price={price}
                        weight={weight}
                        img={img}
                        description={description}
                        count={count}
                        setCount={setCount}
                        close={() => setOpenModal(false)}
                        handleAddToBasketOnClick={handleAddToBasketOnClick}
                        handleDeleteFromBasketOnClick={handleDeleteFromBasketOnClick}
                    />
                </Modal>
            </div>
        )
    );
};

export default BasketProduct;
