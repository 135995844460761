import React, { useEffect, useState } from 'react';
import './styles.scss'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Backdrop, Modal } from '@mui/material';
import ModalPromotion from '../ModalPromotion/ModalPromotion';

const Promotion = ({id, img, content})=>
{
    const [openModal, setOpenModal] = useState(false)

    useEffect(() => {
      console.log('om: ', openModal)
    }, [openModal])


    return(
        <div className='Promotion' onClick={()=>!openModal&&setOpenModal(true)}>
            <img src={img} alt="" />


            <Modal
                open={openModal}
                onClose={()=>setOpenModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                backdrop: {
                    timeout: 1000,
                },
                }}
            >
                <ModalPromotion img={img} description={content} close={()=>setOpenModal(false)}/>
            </Modal>
        </div>
    );
};

export default Promotion;