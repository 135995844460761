import React, { useState, forwardRef } from 'react';
import './styles.scss';
import { Close } from '@mui/icons-material';
import { Button, FormControl, FormControlLabel, Radio, RadioGroup, IconButton } from '@mui/material';

const ModalSelectWokSouce = forwardRef(({close, set_souce}, ref) => {
    const [selectedSouce, setSelectedSouce] = useState('');


    const handleRadioChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedSouce(selectedValue);
        set_souce(selectedValue);
    };

    return (
        <div ref={ref} className='ModalSelectWokSouce' tabIndex={1}>
            <div className="content-modal_promotion">
                <h3>Выберите соус для вока:</h3>
                <FormControl>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        value={selectedSouce}
                        onChange={handleRadioChange}
                    >
                        <FormControlLabel value="Кимчи" control={<Radio />} label="Кимчи" />
                        <FormControlLabel value="Терияки" control={<Radio />} label="Терияки" />
                        <FormControlLabel value="Пекинский" control={<Radio />} label="Пекинский" />
                    </RadioGroup>
                </FormControl>
            </div>
            <Button disabled={!selectedSouce} onClick={()=>close()}>Готово</Button>
            <IconButton className='close-modal_product' onClick={close}><Close /></IconButton>
        </div>
    );
});

export default ModalSelectWokSouce;
