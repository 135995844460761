import React, { forwardRef } from 'react';
import './styles.scss';
import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';

const ModalPromotion = forwardRef(({ img, description, close }, ref) => {

    return (
        <div ref={ref} className='ModalPromotion'>
            <img src={img} alt="" />
            <div className="content-modal_promotion">
                <p>{description}</p>
            </div>
            <IconButton className='close-modal_product' onClick={close}><Close /></IconButton>
        </div>
    );
});

export default ModalPromotion;
