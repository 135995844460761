import axios from "axios";
import { clearBasket, createUpdateBasketEvent, getBasketIds } from "../storage/LocalStorage";

export async function makeOrder(name, address, phone, isPickup, try_count=0) {
    const products = getBasketIds()
    if (address=="Самовывоз")
    {
        isPickup = true;
    }
    try {
        const orderbacket = Object.entries(products).map(([product, quantity]) => ({
            product,
            quantity
        }));

        const requestData = {
            phone_number: phone,
            name: name,
            address: address,
            is_pickup: isPickup,
            orderbacket: orderbacket
        };

    const response = await axios.post(
      "https://api.sushi-dobriy.ru/en/api/orders/",
      requestData,
    );

        if (response.status>=200 && response.status<300)
        {
            clearBasket()
            createUpdateBasketEvent()
            return true
        }
        else {
            console.log('try-count ', try_count)
            if (try_count < 3)
            {
                makeOrder(name, address, phone, isPickup, try_count+1)
            }
            else return false
        }

    } catch (error) {
        console.error('Error adding order: ', error);
        console.log('try-count ', try_count)
        if (try_count < 3)
        {
            makeOrder(name, address, phone, isPickup, try_count+1)
        }
        else return false
        return true
    }
}
