import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './styles.scss';
import Home from '../../pages/Home/Home';
import Menu from '../../pages/Menu/Menu';
import Basket from '../../pages/Basket/Basket';
import Delivery from '../../pages/Delivery/Delivery';
import About from '../../pages/About/About';
import Contacts from '../../pages/Contacts/Contacts';


const AppRoutes = ({}) => {
return (
    <div id='Main'>
        <Routes>
            <Route exact path='/' element={<Home />} />
            <Route exact path='/menu' element={<Menu />} />
            <Route exact path='/basket' element={<Basket />} />
            <Route exact path='/delivery' element={<Delivery />} />
            <Route exact path='/about' element={<About />} />
            <Route exact path='/contacts' element={<Contacts />} />
        </Routes>
    </div>
);
}

export default AppRoutes;
