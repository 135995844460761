import React, { useEffect, useState } from 'react';
import './styles.scss';
import ProductsContainer from '../../components/Products/ProductsContainer/ProductsContainer';
import Product from '../../components/Products/Product/Product';
import ChipMenu from '../../components/ChipMenu/ChipMenu';
import { getProducts } from '../../services/producs';

const Menu = () => {
    const [selectedItem, setSelectedItem] = useState("роллы");
    const [menu, setMenu] = useState({});
    const [allProducts, setAllProducts] = useState([]);

    useEffect(() => {
        async function fetchData() {
            const { productsByCategory } = await getProducts();
            setMenu(productsByCategory);

            const allProductsArray = Object.values(productsByCategory).flat();
            setAllProducts(allProductsArray);
        }
        fetchData();
    }, []);

    const renderProduct = (product) => {
        let relatedProducts = [];

        if (product.name.toLowerCase().includes('вок')) {
            const baseProductName = product.name.trim();

            relatedProducts = allProducts.filter(p =>
                p.name.startsWith(baseProductName + ' ')
            );

            if (relatedProducts.length === 0) {
                relatedProducts = [];
            }
        }

        return (
            <Product
                key={product.id}
                id={product.id}
                title={product.name}
                price={product.price}
                weight={product.weight}
                img={product.img}
                description={product.description}
                relatedProducts={relatedProducts} 
            />
        );
    };

    return (
        <div className='Menu pageMarginContainer'>
            <h1>Меню</h1>

            <ChipMenu 
                selectMenu={setSelectedItem} 
                selectedMenu={selectedItem}
            />

            {selectedItem === 'роллы' ? (
                <>
                    <ProductsContainer title="Роллы">
                        {menu["Холодные роллы"]?.map(renderProduct)}
                    </ProductsContainer>
                    <ProductsContainer title="Горячие роллы">
                        {menu["Горячие роллы"]?.map(renderProduct)}
                    </ProductsContainer>
                    <ProductsContainer title="Суши">
                        {menu["Суши"]?.map(renderProduct)}
                    </ProductsContainer>
                </>
            ) : (
                <ProductsContainer title={selectedItem}>
                    {menu[selectedItem.charAt(0).toUpperCase() + selectedItem.slice(1)]?.map(renderProduct)}
                </ProductsContainer>
            )}
        </div>
    );
};

export default Menu;
