export function addToBasket(id)
{
    let total = Number(localStorage.getItem('totalProducts'))
    localStorage.setItem('totalProducts', total+1)
    let prod = Number(localStorage.getItem("prod"+id))
    localStorage.setItem("prod"+id,prod+1)

    createUpdateBasketEvent()
}

export function delFromBasket(id)
{
    let total = Number(localStorage.getItem('totalProducts'))
    if ((total == 0)||(total == null))
        localStorage.setItem('totalProducts', 0)
    else 
        localStorage.setItem('totalProducts', total-1)

    let prod = Number(localStorage.getItem("prod"+id))
    if ((prod == 0)||(prod == null))
        localStorage.setItem("prod"+id, 0)
    else 
        localStorage.setItem("prod"+id, prod-1)

    createUpdateBasketEvent()
}


export function getProductCount(id)
{
    return Number(localStorage.getItem("prod"+id) || 0)
}


export function createUpdateBasketEvent()
{
    const event = new Event('basketCountUpdated')
    window.dispatchEvent(event)
}

export function getBasketIds() {
    const products = {};
    for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);

        const match = key.match(/^prod(\d+)$/);
        if (match) {
            const productId = parseInt(match[1], 10);
            const quantity = parseInt(localStorage.getItem(key), 10);
            if (quantity > 0) {
                products[productId] = quantity;
            }
        }
    }

    return products;
}

export function clearBasket()
{
    for (let i = localStorage.length - 1; i >= 0; i--) {
        const key = localStorage.key(i);
        if (/^prod\d+$/.test(key)) {
            localStorage.removeItem(key);
        }
    }
    localStorage.removeItem('totalProducts');
}




