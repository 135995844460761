import React from 'react';
import './styles.scss'

const Contacts = ({})=>
{
    return(
        <div className='Contacts pageMarginContainer tinyContainer' >
            <h1>Контактная информация</h1>

            <h3><span>Адрес:</span> город Калуга, Калужская область, улица Суворова, 113</h3>
            <h3><span>Номер телефона:</span> +7 (915) 896-00-07</h3>
            <iframe className='map-delivery' src="https://yandex.ru/map-widget/v1/?um=constructor%3A2e73dc1957eaa1adef74162a64a46fcbf7c5b4bcf76a94294494b7e203d173d0&amp;source=constructor" width="320" height="240" frameborder="0"></iframe>
        </div>
    );
};

export default Contacts;