import React from 'react';
import './styles.scss'

const ProductsContainer = ({children, title})=>
{
    return(
        <div className='ProductsContainer' >
            <h2>{title}</h2>
            <div className="prods-product_container">
                {children}
            </div>
        </div>
    );
};

export default ProductsContainer;