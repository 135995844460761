import React, { useEffect, useState } from 'react';
import './styles.scss';
import { Backdrop, Button, Modal } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import { addToBasket, delFromBasket, getProductCount } from '../../../storage/LocalStorage';
import ModalProduct from '../ModalProduct/ModalProduct';
import ModalSelectWokSouce from '../ModalProduct/ModalSelectWokSouce/ModalSelectWokSouce';

const Product = ({ id, title, price, weight, img, description, relatedProducts = [] }) => {
    const [count, setCount] = useState(getProductCount(id));
    const [openModal, setOpenModal] = useState(false);
    const [openModalWok, setOpenModalWok] = useState(false);
    const [souce, setSouce] = useState('');
    const [currentProductId, setCurrentProductId] = useState(id);

    const isWok = title.toLowerCase().includes('вок');
    const baseProductName = title.trim();

    useEffect(() => {
        if (souce && isWok) {
            const selectedProduct = relatedProducts.find(p => p.name.toLowerCase() === `${baseProductName.toLowerCase()} ${souce.toLowerCase()}`);
            if (selectedProduct) {
                setCurrentProductId(selectedProduct.id);
                setCount(getProductCount(selectedProduct.id));
            } else {
                setCurrentProductId(id);
            }
        } else {
            setCurrentProductId(id);
        }
    }, [souce, isWok, relatedProducts, baseProductName, id]);

    const handleAddToBasketOnClick = () => {
        if (isWok) {
            setSouce('');
            setOpenModalWok(true);
        } else {
            setCount(count + 1);
            addToBasket(currentProductId);
        }
    };

    const handleDeleteFromBasketOnClick = () => {
        if (count > 0) {
            setCount(count - 1);
            delFromBasket(currentProductId);
        }
    };

    const closeModalSelectWokSouce = () => {
        setOpenModalWok(false);
        if (!souce || souce === '') {
            return;
        } else {
            setCount(count + 1);
            addToBasket(currentProductId);
        }
    };

    return (
        <div className='Product'>
            <button className='open_modal-product' onClick={() => setOpenModal(true)}>
                <img src={img} alt="" />
            </button>
            <div className="content-product">
                <button className='open_modal-product' onClick={() => setOpenModal(true)}>
                    <div className="title_desc-product">
                        <h3>{title}</h3>
                        <p>{description}</p>
                    </div>
                </button>
                <div className="price_weight_button-product">
                    <div className="price_weight-product">
                        <span>{price} руб.</span>
                        <span>{weight}</span>
                    </div>
                    <div className="count_button-product">
                        {!count ? (
                            <Button onClick={handleAddToBasketOnClick} className='add_button-product'>Добавить</Button>
                        ) : (
                            <>
                                <Button className='plus_minus-product' onClick={handleDeleteFromBasketOnClick}><Remove /></Button>
                                <span>{count}</span>
                                <Button className='plus_minus-product' onClick={handleAddToBasketOnClick}><Add /></Button>
                            </>
                        )}
                    </div>
                </div>
            </div>

            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 1000,
                    },
                }}
            >
                <ModalProduct
                    id={currentProductId}
                    title={title}
                    price={price}
                    weight={weight}
                    img={img}
                    description={description}
                    count={count}
                    setCount={setCount}
                    close={() => setOpenModal(false)}
                    handleAddToBasketOnClick={handleAddToBasketOnClick}
                    handleDeleteFromBasketOnClick={handleDeleteFromBasketOnClick}
                />
            </Modal>

            {isWok && (
                <Modal
                    open={openModalWok}
                    onClose={closeModalSelectWokSouce}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                        backdrop: {
                            timeout: 1000,
                        },
                    }}
                >
                    <ModalSelectWokSouce
                        id={id}
                        close={closeModalSelectWokSouce}
                        set_souce={setSouce}
                        souce={souce}
                    />
                </Modal>
            )}
        </div>
    );
};

export default Product;
