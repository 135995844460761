import React, { useEffect, useState } from 'react';
import './styles.scss';
import ProductsContainer from '../../components/Products/ProductsContainer/ProductsContainer';
import Product from '../../components/Products/Product/Product';
import { getProducts } from '../../services/producs';

const AllProductsShow = () => {
  const [menu, setMenu] = useState({});
  const [allProducts, setAllProducts] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const { productsByCategory } = await getProducts();
      setMenu(productsByCategory);
    
      const allProductsArray = Object.values(productsByCategory).flat();
      setAllProducts(allProductsArray);
    }
    fetchData();
  }, []);

  const renderProduct = (product) => {
    let relatedProducts = [];

    if (product.name.toLowerCase().includes('вок')) {
      const baseProductName = product.name.trim();

      relatedProducts = allProducts.filter(p =>
        p.name.startsWith(baseProductName + ' ')
      );

      if (relatedProducts.length === 0) {
        relatedProducts = [];
      }
    }

    return (
      <Product
        key={product.id}
        id={product.id}
        title={product.name}
        price={product.price}
        weight={product.weight}
        img={product.img}
        description={product.description}
        relatedProducts={relatedProducts}
      />
    );
  };

  return (
    <div className='AllProductsShow pageMarginContainer'>
      {Object.keys(menu)
        .filter((category) => !category.endsWith('(-)'))
        .map((category) => (
          <ProductsContainer key={category} title={category}>
            {menu[category]?.map(renderProduct)}
          </ProductsContainer>
      ))}
    </div>
  );
};

export default AllProductsShow;
