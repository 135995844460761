import React from 'react';
import PromotionsContainer from '../../components/Promotions/PromotionsContainer/PromotionsContainer'
import Promotion from '../../components/Promotions/Promotion/Promotion'
import './styles.scss'
import AllProductsShow from '../../components/AllProductsShow/AllProductsShow';
import promotion1 from  '../../assets/images/promotion1.webp'
import promotion2 from  '../../assets/images/promotion2.webp'
import promotion3 from  '../../assets/images/promotion3.webp'
import promotion4 from  '../../assets/images/promotion4.webp'

const Home = ({props})=>
{
    return(
        <div className='Home' >
            <PromotionsContainer>
                    <Promotion id={1} img={promotion1} content={"Бесплатная доставка при заказе от 700 рублей по городу."}/>
                    <Promotion id={2} img={promotion2} content={"Скидка 15% на самовывоз с 12 до 16."}/>
                    <Promotion id={3} img={promotion3} content={"Пицца Маргарита в подарок при заказе 2 пицц."}/>
                    <Promotion id={4} img={promotion4} content={"Скидка 10% на первый заказ."}/>
            </PromotionsContainer>

            <AllProductsShow/>
        </div>
    );
};

export default Home;