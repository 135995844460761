import React from 'react';
import './styles.scss';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";


const PromotionsContainer = ({ children }) => {
    return (
        <div className='PromotionsContainer'>
            <Carousel showArrows={true} 
            swipeable
            autoPlay
            emulateTouch
            infiniteLoop
            interval={4000}
            showStatus={false}
            transitionTime={800}
            showThumbs={false}
            autoFocus={false}
            
                className='slider-prom_container'            
            >
                {React.Children.map(children, (child, index) => (
                    <div key={index} className='slide-prom_container'>
                        {child}
                    </div>
                ))}
            </Carousel>
        </div>
    );
};

export default PromotionsContainer;
