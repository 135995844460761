import React, { forwardRef } from 'react';
import './styles.scss';
import { addToBasket, delFromBasket } from '../../../storage/LocalStorage';
import { Button, IconButton } from '@mui/material';
import { Add, Close, Remove } from '@mui/icons-material';

const ModalProduct = forwardRef(({ id, title, price, weight, img, description, count, setCount, close, handleAddToBasketOnClick, handleDeleteFromBasketOnClick }, ref) => {


    return (
        <div ref={ref} className='ModalProduct'>
            <img src={img} alt="" />
            <div className="content-modal_product">
                <div className="title_desc-product">
                    <h3>{title}</h3>
                    <p>{description}</p>
                </div>
                <div className="price_weight_button-product">
                    <div className="price_weight-product">
                        <span>{price} руб.</span>
                        <span>{weight}</span>
                    </div>
                    <div className="count_button-modal_product">
                    {!count? <Button onClick={handleAddToBasketOnClick} className='add_button-product'>Добавить</Button>:
                        <>
                            <Button className='plus_minus-modal_product' onClick={handleDeleteFromBasketOnClick}><Remove/></Button>
                            <span>{count}</span>
                            <Button className='plus_minus-modal_product' onClick={handleAddToBasketOnClick}><Add/></Button>
                        </>
                    }
                    </div>
                </div>
            </div>
            <IconButton className='close-modal_product' onClick={close}><Close /></IconButton>
        </div>
    );
});

export default ModalProduct;
