import React from 'react';
import './styles.scss'

const Delivery = ({props})=>
{
    return(
        <div className='Delivery pageMarginContainer tinyContainer' >
            <h1>Доставка по городу Калуга</h1>
            <div>
                <h2>Телефон</h2>
                <h3 className='accent-delivery'>+7 (915) 896-00-07</h3>
            </div>
            <h3>Минимальная сумма заказа для бесплатной доставки 700</h3>
            <h3>С 12:00 до 16:00 скидка на самомывоз 15%</h3>
            <div>
                <h2>График работы</h2>
                <h3 className='accent-delivery'>Ежедневно с 10:00 до 22:00</h3>
            </div>

            <div>
            <iframe className='map-delivery' src="https://yandex.ru/map-widget/v1/?um=constructor%3A2e73dc1957eaa1adef74162a64a46fcbf7c5b4bcf76a94294494b7e203d173d0&amp;source=constructor" width="320" height="240" frameborder="0"></iframe>
            </div>
        </div>
    );
};

export default Delivery;