import React from 'react';
import './styles.scss'
import logo from '../../assets/images/logo.webp'
import { Instagram, Telegram } from '@mui/icons-material';
import { Button } from '@mui/material';

const Footer = ({props})=>
{
    return(
        <div className='Footer' >
            <div className='section-footer main-footer'>
                <div className='logo-footer'>
                    <img src={logo} alt="" />
                </div>
                <div className="menu-footer">
                    <a href="/">Главная</a>
                    <a href="/menu">Меню</a>
                    <a href="/basket">Корзина</a>
                    <a href="/delivery">Доставка</a>
                    <a href="/about">О нас</a>
                    <a href="/contacts">Контакты</a>
                </div>
                {/* <div className='contacts-footer'>
                    <Button><Telegram/></Button>
                    <Button><Instagram/></Button>
                </div> */}
            </div>
            <div className='section-footer phone-footer'>
                <h3>+7 (915) 896-00-07</h3>
                <hr />
            </div>
            <div className='section-footer'>
                <p>город Калуга, Калужская область, улица Суворова, 113</p>
                <hr />
            </div>
            <div className='section-footer info-footer'>
                <span>Добрый суши-бар, 2024</span>                
                <p>
                Информация на сайте носит справочный характер. Внешний вид и состав блюд могут отличаться от представленных на сайте.
                Подробности у операторов.</p>
            </div>
        </div>
    );
};

export default Footer;