import React from 'react';
import './styles.scss'

const About = ({})=>
{
    return(
        <div className='About pageMarginContainer tinyContainer' >
<h1>О нас</h1>

<p>
    Добро пожаловать в суши-бар <span>«Добрый»</span> — ваш надежный партнер в мире вкусной и быстрой доставки японской кухни. Мы специализируемся на том, чтобы дарить вам свежие, качественные блюда, не выходя из дома или офиса.
</p>

<p>
    Наше имя — <span>«Добрый»</span> — говорит само за себя: мы заботимся о каждом клиенте и делаем всё, чтобы ваше впечатление от нашей еды и сервиса было тёплым и радостным. В каждом заказе — только лучшие продукты, свежайшая рыба, качественные морепродукты и идеальный рис, приготовленные с любовью и по традициям японской кухни.
</p>

<p>
    Мы понимаем, как важно получать еду вовремя и в идеальном состоянии, поэтому наша служба доставки работает оперативно, чтобы вы могли насладиться горячими блюдами в нужный момент. Выбирайте из нашего широкого ассортимента классических роллов, суши и авторских блюд, и мы привезем их к вам максимально быстро.
</p>

<h3>Почему стоит выбрать суши-бар <span>«Добрый»</span> для доставки:</h3>

<ul>
    <li><span>Свежесть и качество</span>: Все наши ингредиенты тщательно отбираются, чтобы обеспечить вам лучшее качество блюд.</li>
    <li><span>Оперативная доставка</span>: Мы делаем все возможное, чтобы вы получили свой заказ точно в срок.</li>
    <li><span>Удобство заказа</span>: Заказать доставку можно легко через наш сайт или по телефону. Мы всегда готовы помочь и учесть ваши пожелания.</li>
    <li><span>Доступные цены</span>: Мы верим, что вкусная японская еда должна быть доступной для всех, поэтому предлагаем блюда по справедливой цене без потери качества.</li>
</ul>

<p>
    Мы заботимся о том, чтобы каждая доставка из суши-бара <span>«Добрый»</span> радовала вас не только вкусом, но и теплом, которым пропитаны наши блюда. Оформляйте заказ и наслаждайтесь японской кухней там, где вам удобно!
</p>

<p>
    Суши-бар <span>«Добрый»</span> — это всегда свежо, вкусно и с душой. Мы приносим японскую кухню в ваш дом!
</p>

        </div>
    );
};

export default About;