import React, { useEffect, useState } from 'react';
import './styles.scss'
import logo from '../../assets/images/logoss.webp'
import { Close, ShoppingBasket } from '@mui/icons-material';
import { Menu as MenuMUI } from '@mui/icons-material';
import { Badge, Drawer, IconButton } from '@mui/material';
import SideMenu from '../SideMenu/SideMenu';
import useWindowSize from '../../services/useWindowSize';

const Header = ({})=>
{

    const [menu, setMenu] = useState(false);
    const [menuSvg, setMenuSvg] = useState(<MenuMUI/>);
    const [basketCount ,setBasketCount] = useState(0)

    const handleOpenMenu = () =>
    {
        setMenu(true)
        setMenuSvg(<Close/>)
    }
    const handleCloseMenu = () =>
    {
        setMenu(false)
        setMenuSvg(<MenuMUI/>)
    }



    useEffect(() => {
        const handleChangeBasketCount = () =>
        {
            setBasketCount(Number(localStorage.getItem('totalProducts'))||0)
        }

        window.addEventListener('basketCountUpdated',handleChangeBasketCount)

        return () =>
        {
            window.removeEventListener('basketCountUpdated', handleChangeBasketCount)
        }
    },[])

        
    useEffect(() => {
            setBasketCount(Number(localStorage.getItem('totalProducts'))||0)
    },[])

    const {height, width} = useWindowSize()

    const MenuOrientation = () =>
    {
        if (width<700)
        {
            return (
                <Drawer
                anchor={'right'}
                open={menu}
                onClose={handleCloseMenu}
                >
                    <SideMenu orientation={0}/>
                </Drawer>
            )
        }
        else return (
                <SideMenu orientation={1}/>
        )
    }
    


    return(
        <div id='Header' >
            <a className="logo-header" href='/'><img src={logo} alt="" /></a>

            <nav>
            {MenuOrientation()}
            <div></div>
            <div className='menu_basket-header'>
                <div className="basket-header menu-item-header"><IconButton
                href='/basket'
                >
                    <Badge badgeContent={basketCount} max={99} color='primary'>
                        <ShoppingBasket/>
                    </Badge>
                </IconButton></div>

                {width<700?
                <div className="menu-header menu-item-header"><IconButton
                onClick={menu?handleCloseMenu:handleOpenMenu}
                >
                    {menuSvg}
                </IconButton></div>
                :""}
                </div>
            </nav>
        </div>
    );
};

export default Header;