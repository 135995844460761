import React from 'react';
import './styles.scss';
import { Button } from '@mui/material';

const ChipMenu = ({ selectMenu, selectedMenu }) => {
    const MenuList = ["Роллы", "Сеты", "Пицца", "Воки", "Закуски", "Напитки"];

    return (
        <div className='ChipMenu'>
            {MenuList.map((menuItem, index) => (
                <Button
                    key={index}
                    color={selectedMenu === menuItem.toLowerCase() ? 'primary' : 'inherit'}
                    variant='contained'
                    onClick={() => selectMenu(menuItem.toLowerCase())} 
                >
                    {menuItem}
                </Button>
            ))}
        </div>
    );
};

export default ChipMenu;
