import './App.scss';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import AppRoutes from './components/Routes/Routes';
import ThemeWrapper from './theme/theme';

function App() {
  return (
    <div className="App">
      <ThemeWrapper>
        <Header/>
        <AppRoutes/>
        <Footer/>
      </ThemeWrapper>
    </div>
  );
}

export default App;
